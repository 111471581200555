import React from 'react'
import { useTranslation } from 'react-i18next'
import { graphql } from 'gatsby'

import Layout from 'components/layout'
import SEO from 'components/seo'

const NotFoundPage = ({ data, location }) => {
  const { t } = useTranslation('notFound')
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title={t('title')} />
      <h1>{t('heading')}</h1>
      <p>{t('message')}</p>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
